import React, { useEffect, useState, useMemo } from 'react'
import classNames from 'classnames/bind'
import { TransitionState } from 'gatsby-plugin-transition-link'
import { useScrollbar } from '@14islands/r3f-scroll-rig/scrollbar'

import Layout from 'layouts'

import { scrollToWindowY } from 'lib/scrollTo'
import { BlogThumbnails } from 'components/ui/BlogSection'
import { Section } from 'components/layout'
import InfoSection from 'components/ui/InfoSection'
import Pagination from 'components/ui/Pagination'
import SEO from 'components/seo'
import Shape from 'components/ui/Shape'
import Spacer from 'components/ui/Spacer'
import VerticalScroll from 'components/layout/VerticalScroll'

import s from './BlogPage.module.scss'
const cn = classNames.bind(s)

const DEFAULT_NUMBER_OF_POSTS_PER_PAGE = 5

const BlogPage = ({ data }) => {
  const [featuredPost, setFeaturedPost] = useState([])
  const [currentPagePosts, setCurrentPagePosts] = useState([])
  const [currentPageNumber, setCurrentPageNumber] = useState(0)
  const [totalAmountOfPosts, setTotalAmountOfPosts] = useState(0)
  const [hide, setHide] = useState(false)
  const [initialRender, setInitialRender] = useState(true)
  const [isPaginationVisible, setPaginationVisibility] = useState(false)
  const { reflow } = useScrollbar()

  const { page } = data.prismic
  if (!page) return null

  const {
    page_meta_title,
    page_meta_description,
    page_meta_thumbnail,
    body,
    featured_post,
    pagination_paragraph,
    posts_per_page,
  } = page
  const postsPerPage = useMemo(() => posts_per_page || DEFAULT_NUMBER_OF_POSTS_PER_PAGE, [
    posts_per_page,
    DEFAULT_NUMBER_OF_POSTS_PER_PAGE,
  ])
  const firstPage = useMemo(() => currentPageNumber === 0, [currentPageNumber])
  const updateFeaturedPost = () => {
    if (featured_post) {
      setFeaturedPost({ blog_post: { ...featured_post, isFeatured: true } })
    }
  }

  const onUpdatePage = (page = 0, userInteraction) => {
    if (!initialRender) setHide(true) /* Do not hide when callback is triggered on mount */

    const pageNumber = userInteraction ? page : currentPageNumber || page
    const filteredPosts =
      (featured_post
        ? data.prismic.posts?.edges?.filter((post) => post.blog_post?._meta?.uid !== featured_post?._meta?.uid) // removes featured post from the posts list
        : data.prismic.posts?.edges) || []
    const initialArrayPosition = postsPerPage * pageNumber
    const finalArrayPosition = initialArrayPosition + postsPerPage
    const updatedCurrentPagePosts = filteredPosts.slice(initialArrayPosition, finalArrayPosition)

    scrollToWindowY(0, 300, () => {
      setTotalAmountOfPosts(filteredPosts.length)
      setCurrentPagePosts(updatedCurrentPagePosts)
      setCurrentPageNumber(pageNumber)
      reflow()
      if (initialRender) setInitialRender(false)
      else setHide(false)
    })
  }

  useEffect(() => {
    updateFeaturedPost()
    if (featured_post) {
      setPaginationVisibility(postsPerPage < data.prismic.posts?.edges.length - 1)
    } else {
      setPaginationVisibility(postsPerPage < data.prismic.posts?.edges.length)
    }
  }, [])

  return (
    <TransitionState>
      {({ transitionStatus } = {}) => {
        return (
          <Layout footerClassName={cn('footer', { hide })}>
            <SEO title={page_meta_title} description={page_meta_description} thumbnail={page_meta_thumbnail} />
            <VerticalScroll>
              <div className={cn('hero', { isVisible: currentPagePosts?.length > 0 })}>
                <div className={cn('inner', { hide })}>
                  {body?.map(({ type, ...props }, index) => {
                    return type === 'info_section' ? (
                      <Section isHidden={transitionStatus === 'entering'} key={index}>
                        <Spacer desktop="9vw" />
                        <InfoSection contentFullWidth data={props} />
                        <Spacer desktop="6.94vw" />
                      </Section>
                    ) : null
                  })}
                  <Shape containerClassName={cn('shapeContainer')} className={cn('shape')} shape="rewind" />
                </div>
              </div>
              <div className={cn('content', { hide })}>
                <BlogThumbnails posts={firstPage ? [featuredPost] : []} expandOnHover hasLargePadding />
                <BlogThumbnails posts={currentPagePosts} expandOnHover hasLargePadding key={`thumbnails-${hide}`} />
                <Spacer initial="2.5rem" desktop="8vw" />
                <Pagination
                  externalPageNumber={currentPageNumber}
                  totalAmountOfItems={totalAmountOfPosts}
                  numberOfItemsPerPage={postsPerPage}
                  onUpdatePageCallback={onUpdatePage}
                  paragraph={pagination_paragraph}
                  isVisible={isPaginationVisible}
                />
                <Spacer initial="9rem" desktop="2.5vw" />
              </div>
            </VerticalScroll>
          </Layout>
        )
      }}
    </TransitionState>
  )
}

export default BlogPage
