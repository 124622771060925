import { graphql } from 'gatsby'

import BlogPage from 'views/blog/BlogPage'

export const query = graphql`
  query BlogPageQuery {
    prismic {
      page: blog_page(uid: "blog", lang: "en-us") {
        posts_per_page
        pagination_paragraph
        page_meta_title
        page_meta_description
        page_meta_thumbnail

        body {
          ... on PRISMIC_Blog_pageBodyInfo_section {
            type
            primary {
              title
              subtitle
              content
              link {
                ...PrismicExtLinkFrag
                ...PrismicDocLinkFrag
              }
              link_text
            }
          }
        }

        featured_post {
          ...PrismicLinkFrag
          ... on PRISMIC_Blog_post {
            title: blog_post_title
            thumbnail_description
            thumbnail
            thumbnailSharp {
              childImageSharp {
                fluid(maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }

      posts: allBlog_posts(sortBy: meta_lastPublicationDate_DESC, first: 100) {
        edges {
          blog_post: node {
            ...PrismicLinkFrag
            ... on PRISMIC_Blog_post {
              title: blog_post_title
              thumbnail_description
              thumbnail
              thumbnailSharp {
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

BlogPage.query = query

export default BlogPage
