import React, { useEffect, useState, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames/bind'
import { motion, useAnimation } from 'framer-motion'

import { RichText } from 'prismic'
import { Container } from 'components/layout'
import { Row, Column12 } from 'components/ui/Grid'
import Clickable from 'components/ui/Clickable'
import Spacer from 'components/ui/Spacer'

import s from './Pagination.module.scss'

const cn = classNames.bind(s)
const defaultOpts = {
  transition: { duration: 0.19, ease: 'easeInOut' },
}

const Pagination = ({
  totalAmountOfItems = 0,
  numberOfItemsPerPage = 0,
  onUpdatePageCallback,
  paragraph,
  externalPageNumber,
  isVisible,
}) => {
  const [activePage, setActivePage] = useState(externalPageNumber || 0)
  const [userInteraction, setUserInteraction] = useState(false)
  const circleControls = useAnimation()
  const innerControls = useAnimation()

  const numberOfPages = useMemo(() => {
    return Math.ceil(totalAmountOfItems / numberOfItemsPerPage) || 1
  }, [totalAmountOfItems, numberOfItemsPerPage])

  useEffect(() => {
    if (onUpdatePageCallback) onUpdatePageCallback(activePage, userInteraction)
  }, [activePage, userInteraction])

  const handleClick = useCallback((number) => {
    /* Move circle */
    circleControls.start({ x: `${number * 100}%`, transition: { type: 'spring', bounce: 0.25 } })

    /* Temporarily hide component to avoid content shift when things move around  */
    setTimeout(() => {
      innerControls
        .start({ ...defaultOpts, opacity: 0 })
        .then(setTimeout(() => innerControls.start({ ...defaultOpts, opacity: 1 }), 850))
      setActivePage(number)
      setUserInteraction(true)
    }, 350)
  }, [])

  return (
    <Container>
      <motion.div animate={innerControls}>
        <div className={cn('container', { hidden: !isVisible })}>
          <div className={cn('buttons')}>
            {numberOfPages > 1 &&
              [...Array(numberOfPages)].map((_item, index) => (
                <Clickable
                  key={index}
                  className={cn('button', { isActive: activePage === index })}
                  onClick={() => handleClick(index)}
                >
                  {index + 1}
                </Clickable>
              ))}
            <motion.div className={cn('circle')} initial={{ x: `${activePage * 100}%` }} animate={circleControls} />
          </div>
        </div>
        {paragraph && (
          <>
            <Spacer initial="2.5rem" desktop="4.79vw" />
            <Row>
              <Column12 initial={12} desktop={6} center>
                <RichText render={paragraph} />
              </Column12>
            </Row>
          </>
        )}
      </motion.div>
    </Container>
  )
}

Pagination.propTypes = {
  totalAmountOfItems: PropTypes.number.isRequired,
  numberOfItemsPerPage: PropTypes.number.isRequired,
  onUpdatePageCallback: PropTypes.func,
  paragraph: PropTypes.array,
  externalPageNumber: PropTypes.number,
  isVisible: PropTypes.bool,
}

export default Pagination
